@font-face {
  font-family: 'Aquire';
  src: local('Aquire'),
    url('../fonts/AquireLight.otf') format('otf');
}

@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'),
    url('../fonts/Comfortaa-Medium.ttf') format('ttf');
}

.App {
  font-family: 'Comfortaa';
  /* font-size: 18px; */
  /* color: white; */
  text-align: left;
}

.App-logo {
  height: 60vmin;
}

.App-logo-scritta {
  width: 75%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 5s normal linear;
  }
}

.App-header {
  background-color: #222222;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}